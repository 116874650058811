import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import wysiwyg from "vue-wysiwyg";

Vue.use(wysiwyg, {
  maxHeight: '500px',
  image: {
    uploadURL: process.env.VUE_APP_API_BASE_URL + '/uploader',
    dropzoneOptions: {}
  },
  forcePlainTextOnPaste: true,
});

import tinymce from 'vue-tinymce-editor'
Vue.component('tinymce', tinymce)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
