import {
  mdiTextBoxOutline,
  mdiTextBoxPlusOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'Content',
    resource: 'Auth',
  },
  {
    icon: mdiTextBoxOutline,
    title: 'Sections',
    to: { name: 'content-sections-index' },
    resource: 'Auth',
  },
  {
    icon: mdiTextBoxOutline,
    title: 'Categories',
    to: { name: 'content-categories-index' },
    resource: 'Auth',
  },
  {
    icon: mdiTextBoxOutline,
    title: 'Articles',
    to: { name: 'content-articles-index' },
    resource: 'Auth',
  },
]
