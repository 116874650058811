const content = [
  {
    path: '/content/articles',
    name: 'content-articles-index',
    component: () => import('@/views/Content/Articles/Index'),
    meta: {
      layout: 'content',
      resource: 'Auth',
      action: '*',
    },
  },
  {
    path: '/content/articles/create',
    name: 'content-articles-create',
    component: () => import('@/views/Content/Articles/Create'),
    meta: {
      layout: 'content',
      resource: 'Auth',
      action: '*',
    },
  },
  {
    path: '/content/articles/:id/edit',
    name: 'content-articles-edit',
    component: () => import('@/views/Content/Articles/Edit'),
    meta: {
      layout: 'content',
      resource: 'Auth',
      action: '*',
    },
  },
  {
    path: '/content/sections',
    name: 'content-sections-index',
    component: () => import('@/views/Content/Sections/Index'),
    meta: {
      layout: 'content',
      resource: 'Auth',
      action: '*',
    },
  },
  {
    path: '/content/sections/create',
    name: 'content-sections-create',
    component: () => import('@/views/Content/Sections/Create'),
    meta: {
      layout: 'content',
      resource: 'Auth',
      action: '*',
    },
  },
  {
    path: '/content/sections/:id/edit',
    name: 'content-sections-edit',
    component: () => import('@/views/Content/Sections/Edit'),
    meta: {
      layout: 'content',
      resource: 'Auth',
      action: '*',
    },
  },
  {
    path: '/content/categories',
    name: 'content-categories-index',
    component: () => import('@/views/Content/Categories/Index'),
    meta: {
      layout: 'content',
      resource: 'Auth',
      action: '*',
    },
  },
  {
    path: '/content/categories/create',
    name: 'content-categories-create',
    component: () => import('@/views/Content/Categories/Create'),
    meta: {
      layout: 'content',
      resource: 'Auth',
      action: '*',
    },
  },
  {
    path: '/content/categories/:id/edit',
    name: 'content-categories-edit',
    component: () => import('@/views/Content/Categories/Edit'),
    meta: {
      layout: 'content',
      resource: 'Auth',
      action: '*',
    },
  },
]

export default content
