import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'

import content from './content'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'auth-login' },
    resource: 'Public',
    action: 'read',
  },
  {
    path: '/second',
    name: 'second',
    component: () => import('@/views/Public/Second.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      action: 'read',
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/Public/About.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Auth/Login.vue'),
    meta: {
      layout: 'blank',
      redirectIfLoggedIn: true,
      resource: 'Public',
      action: 'read',
    },
  },
  ...content,
  {
    path: '/error/404',
    name: 'error-404',
    component: () => import('@/views/Errors/404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      action: 'read',
    },
  },
  {
    path: '/error/401',
    name: 'error-401',
    component: () => import('@/views/Errors/401.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      action: 'read',
    },
  },
  {
    path: '*',
    redirect: 'error/404',
    resource: 'Public',
    action: 'read',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = localStorage.getItem('userData') !== null
    && localStorage.getItem('accessToken') !== null
    && localStorage.getItem('userAbility') !== null

  if (isLoggedIn && to.meta.redirectIfLoggedIn) {
    next({ name: 'content-articles-index'})
  }

  if (!canNavigate(to)) {
    if (!isLoggedIn) return next({ name: 'auth-login' })
    return next({ name: 'error-401' })
  }

  return next()
})

export default router
